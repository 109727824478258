<template>
  <div class="myCard">
    <div class="newCard-left">
      <img width="100%" height="100%" :src="img" alt="">
    </div>
    <div class="newCard-right">
      <p>{{ date }}</p>
      <h4>{{ name }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "newCard",
  props:['date','name','img'],
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
h4:hover{
  text-decoration: underline;
  cursor: pointer;
}
.myCard{
  background-color: rgba(247, 247, 247, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25vw;
  height: 18vh;
  border: none;
  .newCard-left{
    width: 46%;
    height: 18vh;
  }
  .newCard-right{
    width: 51%;
    height: 18vh;
  }
}
</style>