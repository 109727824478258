<template>
    <div>
      <div class="knows">
        <studyHead></studyHead>
        <div>
          <div class="allN">
            <newCard class="card" :img="item.cover_image" :date="item.create_date" :name="item.article_name" v-for="item of dataInfo"></newCard>
          </div>
          <div style="text-align: center">
            <el-pagination
                @current-change="handleCurrentChange"
                style="margin: 7vh auto 5vh"
                background
                size="9"
                layout="prev, pager, next"
                :total="count">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ConsultantForm from "@/components/ConsultantForm";
import newCard from "@/components/newCard";
import studyHead from "@/components/studyHead";
import { constants } from '../../services/constants'
import {schoolService} from "@/services/school";
import {httpService} from "@/services/http";

export default {
  components:{
    ConsultantForm,
    newCard,
    studyHead
  },
  data(){
    return{
      count:0,
      next:'',
      dataInfo:''
    }
  },
  created() {
    httpService.get(constants.BLOGS + `?category=7`).then((data) => {
      this.count = data.body.count
      this.dataInfo = data.body.results

    })
  },
  methods:{
    handleCurrentChange(val) {//分页功能
      httpService.get(constants.BLOGS +`?page=${val}&category=7`).then((data) => {
        this.dataInfo = data.body.results
        console.log(data)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.knows{
  width: 80%;
  margin: 0 auto;
  .allN{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .card{
      margin-top: 3vh;
    }
  }
}
</style>

